<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    class="dt-listing-simple"
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template #[`item.print`]="{ item }">
      <invoice-print-select
        :value="item.print"
        @change="updateCompanyMovementType($event, 'print', item)"
      />
    </template>

    <template #[`item.price_with_tax`]="{ item }">
      <v-switch
        :input-value="item.price_with_tax"
        @change="updateCompanyMovementType($event, 'price_with_tax', item)"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import type { CompanyMovementTypeData } from "@planetadeleste/vue-mc-gw";
import { CompanyMovementTypeCollection } from "@planetadeleste/vue-mc-gw";
import { AppModule } from "@/store/app";
import InvoicePrintSelect from "@/components/form/fields/InvoicePrintSelect.vue";

@Component({
  components: { InvoicePrintSelect },
})
export default class CompanyMovementTypeTable extends Mixins(DataTableMixin) {
  obCollection: CompanyMovementTypeCollection =
    new CompanyMovementTypeCollection();

  loading = false;
  headers: DataTableHeader[] = [
    { text: "name", value: "movement_type_config.name", sortable: false },
    { text: "invoice.print", value: "print", sortable: false },
    {
      text: "invoice.price.with.tax",
      value: "price_with_tax",
      sortable: false,
    },
  ];

  get company() {
    return AppModule.company;
  }

  get items(): Partial<CompanyMovementTypeData>[] {
    return this.obCollection.getModelList();
  }

  async updateCompanyMovementType(
    sValue: any,
    sPath: string,
    obData: Partial<CompanyMovementTypeData>
  ) {
    const obMovement = this.obCollection.find({ id: obData.id });
    if (!obMovement) {
      return;
    }

    this.loading = true;
    obMovement.set(sPath, sValue);
    await obMovement.store();
    await AppModule.loadCompanyMovementTypeConfig();
    this.loading = false;
  }

  async loadCompanyMovementTypes() {
    this.loading = true;
    await this.obCollection.filterBy({ company: this.company.id }).fetch();
    this.loading = false;
  }

  async mounted() {
    this.mapDTHeaders();
    await this.loadCompanyMovementTypes();
  }
}
</script>
