<template>
  <v-form v-if="settings" class="fill-height" @submit.prevent>
    <gw-form-observer
      :loading="bLoading"
      hide-cancel-action
      hide-top-actions
      @save="save"
    >
      <v-tabs v-model="tab" right>
        <v-tab v-for="(item, index) in tabItems" :key="`tab-item-${index}`">
          {{ $t(item.text) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) in tabItems"
          :key="`tab-content-${index}`"
        >
          <settings-form
            v-if="item.value === 'settings-form'"
            :value="settings"
            @input="onSetData"
          />
          <component :is="item.value" v-else v-bind="obBindAttr" />
        </v-tab-item>
      </v-tabs-items>
    </gw-form-observer>
  </v-form>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CompaniesMixin from "@/modules/companies/mixins/CompaniesMixin";
import { AppModule } from "@/store/app";
import type { CompanySettingsData } from "@planetadeleste/vue-mc-gw";
import { CompanySettings } from "@planetadeleste/vue-mc-gw";

import SettingsForm from "@/modules/companies/components/SettingsForm.vue";
import { assign, get, isEmpty, isObject } from "lodash";
import type { SelectItemData } from "@/types/utils";
import CompanyInvoiceTypeTable from "@/modules/companies/components/CompanyInvoiceTypeTable.vue";
import CompanyMovementTypeTable from "@/modules/companies/components/CompanyMovementTypeTable.vue";
import CompanyCurrenciesRates from "@/modules/companies/components/tabs/CurrenciesRates.vue";
import CompanyLabels from "@/modules/companies/components/tabs/Labels.vue";

@Component({
  components: {
    CompanyMovementTypeTable,
    CompanyInvoiceTypeTable,
    CompanyCurrenciesRates,
    CompanyLabels,
    SettingsForm,
  },
})
export default class CompanySettingsForm extends Mixins(CompaniesMixin) {
  bLoading = false;
  obSettings: CompanySettings = new CompanySettings();
  tab = 0;
  tabItems: SelectItemData[] = [
    { text: "form.tab.settings", value: "settings-form" },
    {
      text: "form.tab.company.movement.type.settings",
      value: "company-movement-type-table",
    },
    {
      text: "form.tab.company.currency.rates",
      value: "company-currencies-rates",
    },
    {
      text: "form.tab.company.labels",
      value: "company-labels",
    },
  ];

  get company() {
    return AppModule.company;
  }

  get settings(): CompanySettings {
    if (!this.obSettings) {
      return this.obSettings;
    }

    const obSettings = this.company.get("settings");

    if (isEmpty(obSettings) || !isObject(obSettings)) {
      return this.obSettings;
    }

    if (obSettings instanceof CompanySettings) {
      this.obSettings.assign(obSettings.attributes);
    } else {
      this.obSettings.assign(obSettings);
    }

    return this.obSettings;
  }

  set settings(obData: CompanySettings) {
    if (!this.obSettings) {
      return;
    }

    const obSettingsData =
      obData instanceof CompanySettings ? obData.attributes : obData;
    this.obSettings.assign(obSettingsData);
  }

  get obBindAttr() {
    const obData: Record<string, any> = {};
    const sTab: string | undefined = get(
      this.tabItems,
      `${this.tab}.value`,
      undefined
    ) as string | undefined;

    if (sTab) {
      switch (sTab) {
        case "company-currencies-rates":
          assign(obData, { value: this.company });
          break;
      }
    }

    return obData;
  }

  mounted() {
    this.obSettings.set("company_id", this.company.id);
  }

  async save() {
    this.bLoading = true;
    this.company.sync();
    const obResponse = await this.company.store();

    if (obResponse) {
      const obBranch = AppModule.branch;
      const obCompany = obResponse.getData().data;
      await AppModule.pickCompany(obCompany);

      if (obBranch) {
        AppModule.pickBranch(obBranch.id);
      }
    }

    this.bLoading = false;
  }

  onSetData(obData: CompanySettingsData | CompanySettings) {
    const obSettingsData =
      obData instanceof CompanySettings ? obData.attributes : obData;

    this.obSettings.set(obSettingsData);
    this.company.set("settings", obSettingsData);
  }
}
</script>
