<template>
  <company-labels-dialog />
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { Company } from "@planetadeleste/vue-mc-gw";
import CompanyLabelsDialog from "@/modules/companies/components/CompanyLabelsDialog.vue";

@Component({
  components: { CompanyLabelsDialog },
})
export default class Labels extends Vue {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;
}
</script>
